<template>
    <div>
        <template v-if="type === 'name' || type === 'id'">
            <router-link
                :to="`/agencies/${item.id}`">
                <span v-if="type === 'name'">{{ item.name }}</span>
                <span v-else>{{ item.id }}</span>
            </router-link>
        </template>
        <template v-else>
            <a
                :href="item.url"
                target="_blank">
                {{ item.url }}
            </a>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    }
};
</script>